import React from 'react';
import Image from './Image';
import Text from './Text';

type Props = {
    children: any;
    image: {
        src: any;
        alt: string;
        title?: string;
        ratio?: number;
    }

}

export default function ImageText (props: Props) {
    const {children, image} = props;


    return  <div className="container">
            <div className="columns is-vcentered">
                <div className="column">
                    <Image src={image.src} alt={image.alt} title={image.title} ratio={image.ratio} />
                </div>

                <div className="column">
                    <Text>{children}</Text>
                </div>
            </div>
        </div>
    
}