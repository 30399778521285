import React from 'react';
import './VerticalFold.scss';
import classnames from 'classnames';
import { Breakpoints } from '../../../utils/Types';

const WrapperDefault = (props) => <div>{props.children}</div>;

type Props = {
    children: Array<any>;
    elementsToShow?: number;
    foldableOnBreakpoints?: Array<Breakpoints>;
    wrapper?: any;
    textCollapsed?:string;
    textOpen?:string;
}

export default function VerticalFold (props: Props) {
    const {children} = props;
    const Wrapper = (props.wrapper) ? props.wrapper : WrapperDefault ;
    const elementsToShow = (props.elementsToShow) ? props.elementsToShow : 3;
    const foldableOnBreakpoints: Array<Breakpoints> = (props.foldableOnBreakpoints) ? props.foldableOnBreakpoints : ["mobile"];
    const isFoldableOn: Array<string> = foldableOnBreakpoints.map(breakpoint => {
        switch (breakpoint) {
            case "desktop":
                return "is-foldable-desktop";
            case "tablet":
                return "is-foldable-tablet";
            case "mobile":
                return "is-foldable-mobile";
        }

        return "";
    })

    const textOpen: string = props.textOpen ? props.textOpen : "weniger";
    const textCollapsed: string = props.textCollapsed ? props.textCollapsed : "weitere";

    const [open, setOpen] = React.useState(false);

    

    return <div className={classnames(["vertical-fold",  open ? "is-open" : "is-closed",  ...(children.length > elementsToShow ? isFoldableOn : [])])} >

        <Wrapper>
            {children.map((c, index) => {
                const itemClass = (index + 1 < elementsToShow) ? "is-open-always" : (index + 1 === elementsToShow) ? "is-open-transparent" : "is-closable";
                let newC = React.cloneElement(c, {className: (c.props.className) ? c.props.className + " " + itemClass : itemClass});
                return newC;
            })}
        </Wrapper>
        

            {children.length > elementsToShow && <div className="foldAction has-text-centered has-text-link is-size-4" onClick={() => { setOpen(!open); }}>
                {!open && <span>{textCollapsed}</span>}
                {open && <span>{textOpen}</span>}

                <svg className="verticalFoldChevron" width="18px" height="10px" viewBox="0 0 9 5" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M7.646,0.375l-3.616,3.687l-3.655,-3.687" style={{fill: "none", fillRule:"nonzero", stroke: "#009ee0", strokeWidth: 0.75}} /></svg>
            </div>}
        
    </div>
}