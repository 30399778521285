import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { IBlockImage } from "./IBlocks";

type Props = {}

function Image(props: Props & IBlockImage) {

    const {src, alt, title, ratio} = props;

    return (
        <div className="container">
            <GatsbyImage
                image={{...src.childImageSharp.gatsbyImageData, aspectRatio: (ratio ? ratio : 1.6)}}
                alt={alt}
                title={(title) ? title : alt} />
        </div>
    );
}

export default Image
