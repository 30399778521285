import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Hero from "../../components/ui/Hero/Hero";
import Section from "../../components/ui/Section/Section";
import ButtonLink from "../../components/ui/Buttons/ButtonLink";
import VerticalFold from "../../components/ui/VerticalFold/VerticalFold";
import ButtonLinkCard from "../../components/ui/Buttons/ButtonLinkCard";
import Questions from "../../components/blocks/Questions";
import ImageText from "../../components/blocks/ImageText";

const MaterialienPage = ({ data }) => {
    const materialTypes: Array<any> = [
        {
            key: "starr",
            title: "Starre Materialien",
            description: "In sich selbst stabil bieten starre Materialien die Grundlage für Schilder in allen Größen und Formen.'",
            items: data.allMaterialienHJson.edges.filter(item => item.node.type === "starr"),
        },
        {
            key: "klebend",
            title: "Klebende Materialien",
            description: "Selbstklebende Folien können überall appliziert werden. Für verschiedene Untergründe als auch für unterschiedliche Anforderungen haben wir immer eine passende Folie.",
            items: data.allMaterialienHJson.edges.filter(item => item.node.type === "klebend"),
        },
        {
            key: "flexibel",
            title: "Flexible Materialien",
            description: "Beweglich in ihrer Form sind unsere flexiblen Materialien ideal für dynamische Anforderungen.",
            items: data.allMaterialienHJson.edges.filter(item => item.node.type === "flexibel"),
        },
    ] 
    return (
        <Layout crumbs={[{ label: "Home", url: "" }, { label: "Leistungen", url: "leistungen" }, { label: "Materialien", url: "materialien"}]} headerColor={"light"} hasWave={false}>
            <Hero 
                title="Unsere Materialien" 
                subtitle="Hier informieren wir über unsere Vielfalt an Materialien. Erfahren Sie mehr über Eigenschaften und Möglichkeiten. Kontaktieren Sie uns und wir beraten Sie gerne." 
                color={"light"}
                size="is-medium"
                hasWaveBottom={["mobile", "tablet"]}
            />

            { materialTypes.map((item, sectionKey) => (
            <Section key={sectionKey}  hasTitle={true}>
                <div className="columns is-block-tablet is-flex-desktop">
                    <div className="column is-one-third-desktop">
                        <div className="content">
                            <h2  className="title">{item.title}</h2>
                            <p>
                                {item.description}
                            </p>
                        </div>
                    </div>
                    <div className="column">
                        
                            <VerticalFold textCollapsed="weitere Materialien" textOpen="weniger Materialien" wrapper={(props) => <div className="columns is-multiline">{props.children}</div>}>
                            {item.items.map((document) => (

                                        <div className="column is-one-third" key={document.node.url}>

                                            <ButtonLinkCard to={`/leistungen/materialien/${document.node.url}`}>
                                                <div className=" is-hidden-mobile">
                                                    <h3 className="title is-4">{document.node.name}</h3>
                                                    <ButtonLink to={`/leistungen/materialien/${document.node.url}`} variation="minimal">
                                                        <span className="is-hidden-desktop-only is-hidden-tablet-only">Mehr Erfahren</span>
                                                        <span className="is-hidden-widescreen">Mehr</span>
                                                    </ButtonLink>
                                                </div>
                                                <div className="content is-hidden-tablet is-hidden-desktop">
                                                    <ButtonLink to={`/leistungen/materialien/${document.node.url}`} variation="minimal" className="is-block"><h3 style={{marginBottom: 0}}>{document.node.name}</h3></ButtonLink>
                                                </div>
                                            </ButtonLinkCard>
                                        </div>
                            ))}
                            </VerticalFold>
                    </div>
                </div>
            </Section>

            ))}


            <Questions />

            <Section size="extraSmall" hasTitle={true}>
                <ImageText image={{src: data.images.edges[0].node, alt: "Druckverfahren im Detail"}}>
                    <h3>Die Vielfalt der Druckverfahren</h3>
                    <p>
                        Der nächste Schritt ist das Beschriften des Materials. Hierfür bieten wir verschiedene Druckverfahren an, die unterschiedliche Vor- und Nachteile bringen. Auch hierfür haben wir alles Wissenswerte zusammengefasst.
                        
                    </p>
                    <ButtonLink to="/leistungen/druckverfahren">Weiter zu den Druckverfahren</ButtonLink>
                </ImageText>
            </Section>
            
                


        </Layout>
    )
}

export default MaterialienPage

export const pageQuery = graphql`query IndexMaterialienQuery {
  allMaterialienHJson {
    edges {
      node {
        description
        img
        name
        type
        url
      }
    }
  }
  images: allFile(
    filter: {relativePath: {regex: "/leistungen/druckverfahren.jpg/"}}
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`
