import classnames from 'classnames';
import React from "react"
import * as styles from "./Text.module.scss";

type Props = {
    children: React.ReactNode;
}

function Text(props: Props) {
    let items = [];
    if (Array.isArray(props.children)) {
        items = props.children;
    } else {
        items = [props.children];
    }

    return <>
        {items.map((item, key) => {
            return (typeof item === "object") ? item : <div key={key} className={classnames(["content", styles.text])} dangerouslySetInnerHTML={{__html: item}} ></div>
        })}
    </>
}

export default Text
