import React from 'react';
import { navigate } from 'gatsby';
import './ButtonLinkCard.scss';
import classnames from 'classnames';

type Props = {
    children: any;
    icon?: "arrow" | "send";
    to: string;
    target?: "blank";
    variation?: "minimal" | "contained"
}

export default (props: Props) => {
    const {children, icon, to, target, variation } = props;

    let buttonClasses = ["button", "is-link", "is-card-button"];

    const onClick = () => {
        if ( target === "blank") {
            window.open(to);
        } else {
            navigate(to);
        }
    }

    return <div onClick={onClick} className={classnames(buttonClasses)}>
            <div className="card">
                <div className="card-content">
                    <div className="content">
                      {(children) ? children : null}
                    </div>
                </div>
            </div>
        </div>
}